import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import NavigationBar from "components/NavigationBar"
import SubHeader from "components/SubHeader"
import Footer from "components/Footer"
import "styles/components/layout.scss"

class Layout extends React.Component {

  componentDidMount() {
    switch (this.props.pathname) {
      case "resume":
        document.body.style.background = "#191919";
        break;
      default:
        // document.body.style.background = "white";
        document.body.style.background = "#191919";
    }
  }

  render() {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              email
            }
          }
        }
      `
    )

    const { email } = site.siteMetadata

    let subHeader;
    if (this.props.pathname === "blog") {
      subHeader = <SubHeader />
    }
    return (
      <div className={`layout ${this.props.pathname}`}>
        <div className="alert">
          <div className="content container">
            <div class="message">This site is under construction. If you have any doubt, please drop me an <a href={`mailto:${email}`}>email</a>.</div>
          </div>
        </div>
        <NavigationBar className="navigation-bar container" pathname={this.props.pathname} />
        {subHeader}
        <div className="page-content">
          {this.props.children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout