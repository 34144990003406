import React, { Component } from "react"
import disableScroll from 'disable-scroll';
import { Link } from "gatsby"
import { Squeeze as Hamburger } from 'hamburger-react'

class NavigationBar extends Component {

  // MARK: - Properties

  routes = [
    { name: "Work", path: "/" },
    // { name: "Blog", path: "/blog/" },
    // { name: "Resume", path: "/resume/" },
    { name: "About", path: "/about/" },
  ]

  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  // MARK: - Lifecycle

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevState) {
    if (this.state.isMenuOpen !== prevState.isMenuOpen) {
      this.state.isMenuOpen ? disableScroll.on() : disableScroll.off();
    }
  }

  // MARK: - Methods

  handleMenuClick() {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen
    }));
  }

  handleResize() {
    const windowSize = window.innerWidth;
    const isLargeWindow = (windowSize > 768);
    if (this.state.isMenuOpen && isLargeWindow) {
      this.setState(state => ({
        isMenuOpen: false
      }));
    }
  };

  render() {

    var menuButtonColor
    switch (this.props.pathname) {
      case "resume":
        menuButtonColor = "white"
        break;
      default:
        menuButtonColor = "black"
    }

    return (
      <div className={`${this.props.className} navbar`}>
        <nav className="header">
          <Link to="/" className="logo" onClick={() => {
            this.setState(state => ({ isMenuOpen: false }))
          }}>
            Naufal Aros
        </Link>
          <div className="nav-items">
            {this.routes.map(route => (
              <Link to={route.path} className="nav-link" activeClassName="nav-link active">{route.name}</Link>
            ))}
            <div className="hamburger-button">
              <Hamburger toggled={this.state.isMenuOpen} toggle={this.handleMenuClick} size={24} color={menuButtonColor} rounded label="Open menu" />
            </div>
          </div>
        </nav>
        <div className={this.state.isMenuOpen ? "menu-overlay open" : "menu-overlay"}>
          <div className="menu container">
            {this.routes.map(route => (
              <Link to={route.path} className="menu-item" activeClassName="menu-item active" onClick={() => this.handleMenuClick()}>{route.name}</Link>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default NavigationBar