import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(fab)

function Footer() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            author {
              name
              twitter
              linkedin
            }
            email
          }
        }
      }
    `
  )

  const { name } = site.siteMetadata.author
  const { email } = site.siteMetadata
  const { twitter } = site.siteMetadata.author
  const { linkedin } = site.siteMetadata.author
  const year = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="container">
        <div className="segment contact">
          <div className="title">Contact</div>
          <a className="item" href={`mailto:${email}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="icon" icon={faEnvelope} /><span>Email</span></a>
          <a className="item" href={`https://twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="icon" icon={['fab', 'twitter']} /><span>Twitter</span></a>
          <a className="item" href={`https://www.linkedin.com/in/${linkedin}/`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="icon" icon={['fab', 'linkedin']} /><span>LinkedIn</span></a>
        </div>
        <div className="info-site">
          This site is built with <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">React</a> and hosted on <a href="https://vercel.com" target="_blank" rel="noopener noreferrer">Vercel</a>. The source code is hosted on <a href="https://github.com" target="_blank" rel="noopener noreferrer">Github</a>.
          </div>
        <div className="separator"></div>
        <div className="footer-base">
          <div className="copyright">Copyright © {year} {name}. All rights reserved.</div>
          <div className="made-in">Made in Europe.</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer