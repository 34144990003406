import React from "react";
import { Link } from "gatsby";

function SubHeader() {

  return (
    <div className="subheader container">
      <Link to="/blog/" className="subheader-logo"><strong>Naufal</strong> Blog</Link>
    </div>
  )
}

export default SubHeader

